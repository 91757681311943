<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <h5>Farm Retreats Details</h5>
      <b-row no-gutters>
        <b-col md="8">
          <b-card class="farm-retreats-details border-0">
            <b-card-text>
              <table class="table table-sm">
                <tr>
                  <th>Name</th>
                  <td>{{ details._projectID.name }}</td>
                </tr>
                <tr>
                  <th>Location</th>
                  <td>{{ details._projectID.location }}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{{ details.description }}</td>
                </tr>

                <tr>
                  <th>Ammenities</th>
                  <td>
                    <span
                      v-for="(amenity, index) in details.amenities"
                      :key="index"
                    >
                      <img
                        :src="amenity._amenitiesID.image"
                        alt=""
                        width="30px"
                        height="30px"
                      />
                      {{ amenity._amenitiesID.name }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Credits</th>
                  <td>
                    <table class="table table-sm credits-table mt-2">
                      <thead>
                        <tr>
                          <th>Land Extent</th>
                          <th>Credits</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(credit, index) in details.credits"
                          :key="index"
                        >
                          <td>{{ credit.acre }}</td>
                          <td>{{ credit.points }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="4" class="text-right">
          <img
            class="img-thumbnail"
            width="300px"
            alt=""
            :src="details.image"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RoomView",
  data() {
    return {
      details: "",
      rooms: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let resortId = this.$route.params.id;
      axios.get("project-resorts/" + resortId).then((resp) => {
        console.log(resp);
        this.details = resp.data.data;
      });
    },
    getRooms() {
      let resortId = this.$route.params.id;
      axios.get("project-resort-rooms/" + resortId).then((resp) => {
        console.log(resp.data.data);
        this.rooms = resp.data.data;
      });
    },
  },
};
</script>

<style scoped>
.farm-retreats-details .card {
  border-radius: 11px;
}

.farm-retreats-details .card-body {
  background-color: #dcffd9;
  border-radius: 11px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.table > tr:first-child > td,
.table > tr:first-child > th {
  border: none;
}

.credits-table thead tr th {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: "Poppins", sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}

.credits-table tbody {
  background-color: #e8f8e6;
}

.credits-table {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
</style>
